$(document).scroll(function () {
    var y = $(document).scrollTop(),
        header = $(".navbar");
    if (y >= 0) {
        header.addClass('fixed-to-top');
    } else {
        header.removeClass('fixed-to-top');
    }
});
$('.review-slider').slick({
    centerMode: true,
    centerPadding: '30px',
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
    nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
    prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
    responsive: [
        {
            breakpoint: 768,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '30px',
                slidesToShow: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '30px',
                slidesToShow: 1
            }
        }
    ]
});
